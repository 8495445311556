
import './App.css';
import {useRef} from 'react';
import {useState} from 'react';
import Axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import * as clipboard from 'clipboard-polyfill';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/private-theming';
import { yellow, red } from '@mui/material/colors';
import Mailchimp from 'react-mailchimp-form';


const theme = createTheme({
  palette: {
    primary: yellow,
    secondary: red
  }
});


function App() {
    const ref = useRef(null);
    const emailRef = useRef(null);
    const [message, setMessage] = useState('');
    const [prayer, setPrayer] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false)

    const [email, setEmail] = useState('');

    const handleClick = () => {
        console.log("button pressed")       
        if (ref.current.value){
            console.log("request submitted")
            let api_url_prefix = "https://s25im6cn2k.execute-api.us-east-1.amazonaws.com/test/getprayer?query="
            let final_api_endpoint = api_url_prefix.concat(ref.current.value)
            setLoadingStatus(true)
            Axios.get(final_api_endpoint).then((response) => {
                console.log("response retrieved")
                console.log(response)
                setLoadingStatus(false)
                setPrayer(response['data'])

            })
            .catch(err=> {
                console.log(err)
                console.log("Oops something went wrong, we'll check it out")
            })

            console.log("thing is done?")
        }
    }

    function MailChimpForm(){

        return (
                <Mailchimp
                    action='https://gmail.us9.list-manage.com/subscribe/post?u=a41aa215f795181b13dd7b4db&id=d67030bbdc&f_id=00fb0de1f0'
                    fields={[
                        {
                        name: 'EMAIL',
                        placeholder: 'Email',
                        type: 'email',
                        required: true
                        },
                    ]}
                    // Change predetermined language
                    messages = {
                        {
                        sending: "Sending...",
                        success: "Thank you for subscribing!",
                        error: "An unexpected internal error has occurred.",
                        empty: "That email looks like it's missing something",
                        duplicate: "Too many subscribe attempts for this email address",
                        button: "Subscribe!"
                        }
                    }
                    className='mailchimp'
                />
                );
    }  


    function ShareButton() {
        const handleClick = () => {
            if (navigator.share) {
            navigator.share({
                title: 'Read this prayer from FaithFlow!',
                text: prayer,
                url: 'http://faithflow.org',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
            } else {
            // Fallback logic for unsupported browsers
            console.log("not supported browser")
            }
        };

        return (
            <Button 
            size = "small"
                    variant= 'outlined'
                        style={{
                            borderColor: 'white',
                            color: 'white'
                        }}
                        onClick={handleClick}
                    > <p style={{ fontSize: '10px' }}>
                        Share
                        </p>
                        {/* <FileCopyIcon style={{ color: '#ffffff' }} /> */}
                </Button>
        );
        }

    function TailwindResponseCode(){
        if (loadingStatus === false){
            const rawPrayer = prayer 
            const lines = rawPrayer.split("\n")

            // while (lines[0] && lines[0].includes('\n')) {lines.shift();}

            while (lines.length > 2 && lines[0] === '' && lines[1] === '') {
            lines.splice(0, 2);
            }

            console.log(lines); // ['entry 3', 'entry 4']

            console.log(lines)
            if (rawPrayer){
                return (
                    <FullyFormattedResponse lines = {lines}/>
                );
            }
        }
        else{ //it is currently loading 
            return <>
                <div className = "flex justify-center items-center m-3">
                    <div className = 'mr-2'>
                        <CircularProgress></CircularProgress> <br></br>
                    </div>
                    <p className = "text-white"> This will take a few seconds...</p>
                </div>
            </>
        }
    }

    function FullyFormattedResponse(props) {
        const copyToClipboard = () => {
            let finalReturn = 'Check out https://www.FaithFlow.com for prayer composition!\n'.concat(prayer) 
            clipboard.writeText(finalReturn);
        };

        return (
            <div style={{
            borderRadius: '5px',
            border: '2px solid white',
            margin: '20px',
            padding: '15px',
            position: 'relative'
            }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '15px'} }>
                <Button 
            size = "small"
                    variant= 'outlined'
                        style={{
                            borderColor: 'white',
                            color: 'white'
                        }}
                        onClick={copyToClipboard}
                    > <p style={{ fontSize: '10px' }}>
                        Copy
                        </p>
                        {/* <FileCopyIcon style={{ color: '#ffffff' }} /> */}
                </Button>
                
                <ShareButton></ShareButton>
            </div>

            <span style={{ color: 'white' }}>
                <div style={{ color: 'white' }}>
                    {props.lines.map((line, index) => (
                        <React.Fragment key={index}>
                        {line}
                        <br />
                        </React.Fragment>
                    ))}
                    </div>
            </span>
            </div>
        );
    }    

    function TailwindCSSButton(){
        return (
            <div>
                <button onClick = {handleClick} type="button" className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-1 mb-1">Compose a Prayer</button>
            </div>
        )
    }

    function TailwindTextArea(){
        return (
            <div>
                <div className = "flex justify-center items-center m-3">
                    <label for="messagething" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">I am grateful for...</label>
                </div>
                <div className = "flex justify-center items-center m-3 mb-2">
                    <textarea 
                        // onChange = {handleMessageChange} 
                        ref = {ref}
                        id="message" 
                        name = "message"
                        // value = {message}
                        rows="4" 
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="type something like: my loving family, the health of my partner, a recent promotion etc. "
                        >
                    </textarea>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className = "flex justify-center items-center m-10">
                <h1 className = "text-4xl font-bold text-white"> FaithFlow</h1>
            </div>
            <div className = "flex justify-center items-center">
                <p className = "font-bold text-white">FaithFlow helps you compose a prayer</p>
            </div>

            <div className = "spacingMedium"> </div>
            <div className = "flex justify-center items-center mt-10">
                <TailwindTextArea> </TailwindTextArea>
            </div>
            <div className = "flex justify-center items-center m-5">
                <TailwindCSSButton></TailwindCSSButton>
            </div>

            <div className = "flex justify-center items-center m-5">
                <TailwindResponseCode></TailwindResponseCode>
            </div>

            <div className = "spacingComponent"> </div>

            <div className = "flex justify-center items-center">
                <Accordion sx={{
                        backgroundColor: "#1f3a43",
                        width: '75%',
                    }}>
                    <AccordionSummary sx={{
                        backgroundColor: "#1f3a43",
                        width: '100%'
                    }} >
                        <Typography style={{color:"#ffffff"}} align="center" sx={{width: '100%' , fontSize: '13px'}}>About us</Typography>
                    </AccordionSummary>
                    <AccordionDetails align = 'center' sx={{
                        backgroundColor: "#1f3a43",
                        width: '100%',
                    }}>
                        <Typography style={{color:"#ffffff"}} align="center" sx={{width: '100%'}}>"At FaithFlow, we are firm believers in the transformative power of prayer. Our prayer composer is designed to help you connect with God who will provide guidance in your journey. Whether you are facing difficulties, seeking comfort, or simply giving thanks, FaithFlow is here to support and uplift you."</Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className = "flex justify-center items-center m-5">
                <Typography style={{color:"#ffffff"}} align="center" sx={{width: '100%', fontSize: '12px'}}>
                    <p className = "font-bold text-white">Subscribe for more faith-focused apps</p>
                </Typography>
            </div>
            <div className = "flex justify-center items-center m-5">
                    <MailChimpForm></MailChimpForm>
            </div>

        </>
    );
}




export default App;
